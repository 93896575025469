<template>
  <div>
    <p v-if="isMainContact">
      {{ $t('account-organisation-information-incorrect') }}
    </p>
    <p v-if="!isMainContact">
      {{ $t('account-organisation-mainuser-info') }}
    </p>
    <b-form @submit.prevent="adjustCustomer">
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-name"
            :label="$t('account-organisation-name-organisation')"
            label-for="input-customer-name"
          >
            <b-form-input
              id="input-customer-name"
              v-model="form.customerName"
              :placeholder="$t('account-organisation-name-organisation')"
              :disabled="isMainContact"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-customer-organisation-number"
            :label="$t('account-organisation-organisationnumber')"
            label-for="input-customer-organisation-number"
          >
            <b-form-input
              id="input-customer-organisation-number"
              v-model="form.customerOrganisationNumber"
              :disabled="isMainContact"
              :placeholder="
                $t('account-organisation-organisationnumber-placeholder')
              "
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="12">
          <b-form-group
            id="input-group-customer-email"
            :label="$t('emailaddress')"
            label-for="input-customer-email"
          >
            <b-form-input
              id="input-customer-email"
              v-model="form.customerEmail"
              :disabled="isMainContact"
              :placeholder="$t('emailaddress')"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-street"
            :label="$t('street')"
            label-for="input-street"
          >
            <b-form-input
              id="input-street"
              v-model="form.CustomerStreet"
              :disabled="isMainContact"
              :placeholder="$t('street')"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumber"
            :label="$t('housenumber')"
            label-for="input-housenumber"
          >
            <b-form-input
              id="input-housenumber"
              v-model="form.housenumber"
              :disabled="isMainContact"
              :placeholder="$t('housenumber')"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="6" md="3">
          <b-form-group
            id="input-group-housenumberaddition"
            :label="$t('addition')"
            label-for="input-housenumberaddition"
          >
            <b-form-input
              id="input-housenumberaddition"
              v-model="form.housenumberaddition"
              :disabled="isMainContact"
              :placeholder="$t('addition')"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-postal"
            :label="$t('zipcode')"
            label-for="input-postal"
          >
            <b-form-input
              id="input-postal"
              v-model="form.zipCode"
              :disabled="isMainContact"
              :placeholder="$t('zipcode')"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-city"
            :label="$t('city')"
            label-for="input-city"
          >
            <b-form-input
              id="input-city"
              v-model="form.city"
              :disabled="isMainContact"
              :placeholder="$t('city')"
              type="text"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>

        <hr />
        <b-col v-if="keyuser" cols="12">
          <h3>{{ $t('account-details-of-reponsible-contact') }}</h3>
          <table class="table">
            <tr>
              <td>{{ $t('firstname') }}</td>
              <td>{{ keyuser.FirstName || this.$t('unknown') }}</td>
            </tr>
            <tr>
              <td>{{ $t('lastname') }}</td>
              <td>{{ keyuser.LastName || this.$t('unknown') }}</td>
            </tr>
            <tr>
              <td>{{ $t('email') }}</td>
              <td>{{ keyuser.Email || this.$t('unknown') }}</td>
            </tr>
            <tr>
              <td>{{ $t('mobile') }}</td>
              <td>{{ keyuser.MobilePhone || this.$t('unknown') }}</td>
            </tr>
            <tr>
              <td>{{ $t('telephone') }}</td>
              <td>{{ keyuser.Tel || this.$t('unknown') }}</td>
            </tr>
          </table>
        </b-col>

        <b-col cols="12">
          <b-button
            v-if="!isMainContact"
            class="mb-2"
            type="submit"
            variant="primary"
            >{{ $t('modify-details') }}</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
const { updateCustomer } = require('@/services/AuthorizationService')
export default {
  props: {
    user: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: {
        customerEmail: '',
        customerName: '',
        customerDescription: '',
        street: '',
        housenumber: '',
        housenumberaddition: '',
        customerOrganisationNumber: '',
        zipCode: '',
        city: '',
        website: '',
        CUST_SocialMedia: ''
      }
    }
  },
  computed: {
    account: function() {
      return this.$store.getters.account
    },
    keyuser: function() {
      return this.$store.state.accounts.find(account => account.IsMainContact)
    },
    customer: function() {
      return this.$store.state.user.Customer
    },

    isMainContact: function() {
      if (this.$store.getters.account.RoleName !== 'portal') {
        return false
      } else {
        return true
      }
    }
  },
  created: function() {
    this.form.customerEmail = this.customer.Email
    this.form.CustomerStreet = this.account.CustomerStreet
    this.form.customerDescription = this.account.CustomerCUST_Notes
    this.form.zipCode = this.customer.ZipCode
    this.form.housenumber = this.account.CustomerHouseNumber
    this.form.housenumberaddition = this.account.CustomerHouseNumberAddition
    this.form.customerOrganisationNumber = this.account.ECCUST_EnterpriseNumber
    this.form.city = this.customer.City
    this.form.customerName = this.account.CompanyName
    this.form.website = this.account.website
    this.form.CUST_SocialMedia = this.user.CUST_SocialMedia
  },
  methods: {
    adjustCustomer: async function() {
      const result = await updateCustomer({
        CustomerEmail: this.form.customerEmail,
        CustomerStreet: this.form.CustomerStreet,
        ZipCode: this.form.zipCode,
        HouseNumber: this.form.housenumber,
        HouseNumberAddition: this.form.housenumberaddition,
        Website: this.form.website,
        City: this.form.city,
        CompanyName: this.form.customerName,
        CustomerDescription: this.account.CustomerCUST_Notes,
        ECCUST_EnterpriseNumber: this.form.customerOrganisationNumber,
        CUST_SocialMedia: this.form.CUST_SocialMedia
      })
      if (result) {
        this.notify({ message: this.$t('InformationSuccesfullyModified') })
      }
    },
    notify({ message }) {
      this.$toasted.show(message)
    }
  }
}
</script>
